import React, { useEffect, useRef, useState } from "react"
import "../index.scss"

import Layout from "src/components/layout"
import Head from "src/components/Head"

import SportObjectsSearch from "src/components/clients/SportObjectsSearch/SportObjectsSearch"
import Faq from "src/components/clients/Faq/Faq"
import Start from "src/components/Start/Start"

import { useTranslation } from "react-i18next"
import { graphql, useStaticQuery } from "gatsby"
import COUNTRY from "../../constants"
import BRANDS from "../../brands"

const questions = [
  {
    question: "objects.questions.question-1.question",
    answer: "objects.questions.question-1.answer",
  },
  {
    question: "objects.questions.question-2.question",
    answer: "objects.questions.question-2.answer",
  },
  {
    question: "objects.questions.question-3.question",
    answer: "objects.questions.question-3.answer",
  },
  {
    question: "objects.questions.question-4.question",
    answer: "objects.questions.question-4.answer",
  },
]

const ClientsMain = ({ location }) => {
  const data = useStaticQuery(graphql`
    query objectPageClientsLtEn {
      site {
        siteMetadata {
          senderPath
          countries {
            lt {
              hotLine {
                tel
                title
                telephone
                telephoneTitle
              }
            }
          }
        }
      }
    }
  `)

  const { t, i18n } = useTranslation()
  useEffect(() => {
    i18n.changeLanguage(COUNTRY.lt_en)
  }, [i18n])

  const [email, setEmail] = useState("")
  const emailDisabled = false
  const [, setForm] = useState({
    company: "",
  })
  const sendForm = (form) => {
    setForm(form)
    return fetch(data.site.siteMetadata.senderPath, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(form),
    })
      .then((response) => {
        alert(t("sendFormAlert"))
      })
      .catch((e) => {
        console.error(e)
      })
  }
  const startRef = useRef()

  return (
    <div className="objects-page">
      <Head
        lang="lt_en"
        title={t("head.objects.title", { brand: BRANDS.SPORTBENEFIT.brand })}
        description={t("head.objects.description", {
          brand: BRANDS.SPORTBENEFIT.brand,
        })}
        slug="/objects/"
      />
      <Layout
        hotLine={data.site.siteMetadata.countries.lt.hotLine}
        location={location}
        country="lt_en"
      >
        <SportObjectsSearch
          location={location}
          titleLt={t("objects.mapTitle")}
          country="lt"
        />
        <Faq
          country={COUNTRY.lt_en}
          questions={questions}
          hotLine={data.site.siteMetadata.countries.lt.hotLine}
        />
        <Start
          type="clients"
          startRef={startRef}
          email={email}
          emailDisabled={emailDisabled}
          setEmail={setEmail}
          setForm={sendForm}
        />
      </Layout>
    </div>
  )
}

export default ClientsMain
